import React from "react";
import { imgDir } from "../../util";
import { useDispatch } from "react-redux";
import { setTransitionStarter } from "../../redux/effectSlice";
import { useNavigate } from "react-router-dom";

const vintageStyle = () => {
    const css = `
    #laszioTypo {
        animation: laszioAnimation 20s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
    @keyframes laszioAnimation {
        0% {
            left:0px;
        }
        100% {
            left: -1068px;
        }
    }
    `
    return (
        <style>
            {css}
        </style>
    )
}

export default function Vintage(props: any) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }
    return (
        <>
            {vintageStyle()}
            <section className="section bg-[#F0F0F0] pt-24 lg:pt-40 pb-20 relative">
                <div className="relative mx-auto">
                    <h6 className="text-center font-bold tracking-wider">the brands we deal with</h6>
                    <div className="relative w-screen overflow-hidden">
                        <div id="laszioTypo" className="absolute top-6 left-0 flex space-x-10 text-[240px] cormorant text-white" style={{width: 1068*2*3 + 'px'}}>
                            <div className="uppercase">
                                VINTAGE
                            </div>
                            <div className="uppercase">
                                VINTAGE
                            </div>
                            <div className="uppercase">
                                VINTAGE
                            </div>
                        </div>
                        <img src={imgDir() + '/vintage@2x.png'}  className="relative block mx-auto h-[300px] mt-8 mb-4"/>
                    </div>
                    <h3 className="text-center font-bold tracking-wider text-3xl">VINTAGE</h3>
                    <div className="max-w-[640px] mx-auto px-4">
                        <p className=" text-sm mt-8 leading-6 zen">
                            次々と生産され、使用され、そして廃棄されていく近代社会。<br />
                            しかしそんな中でも革靴は「磨いて修理して長く履く」ということが根付いた、ある意味でサスティナブルな存在であるとも言えます。<br />
                            まだまだ履くことができる、持ち主の手を離れた靴たち…。<br />
                            もしくは、もう手に入らない希少な靴たち…。<br />
                            それらを再生し世に送り出すのも、我々靴屋の使命だと思っています。<br />
                            見た目は気に入っているのにサイズが合わない…<br />
                            趣味が変わったけれど思い入れがあって手放せない…<br />
                            クロークに納まりきらなくて困っている…<br />
                            そんな眠っている名靴を新たなオーナーに。<br />
                            <br />
                            憧れの靴があるけれど高すぎて…<br />
                            今はもう売っていないあの靴が欲しい…<br />
                            革靴初心者だけど一度良い靴を試してみたい…<br />
                            埃を被っていた名靴に新たな息吹を。<br />
                            <br />
                            買取も販売もお気軽にご相談ください。
                        </p>

                    </div>

                </div>
            </section>
            <section className="bg-white pt-14 pb-40">
                <div className="max-w-[740px] mx-auto px-4 lg:px-0">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8">
                        <div className="flex flex-col">
                            <img src={imgDir() + '/d-te/laszlo/shoe.png'}  className="relative block mx-auto h-[300px] object-contain w-auto mt-8 mb-4"/>
                            <h3 className="text-center font-bold tracking-wider text-2xl">Laszlo Budapest</h3>
                            <p className=" text-sm zen mt-6 leading-6 grow">
                                ヨーロッパ有数の靴生産地であるハンガリーで100年以上の歴史を持つ、隠れた名門。ハンドメイドの靴作りの技術を、職人たちが今も守り続けています。
                            </p>
                            <div className="flex justify-center mt-8">
                                <button onClick={()=>linkTo("/d-te/laszlo-budapest")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                    <div className="absolute inset-0 border border-black ">
                                    </div>
                                    MORE
                                    <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                    MORE
                                    </div>
                                </button>
                            </div>

                        </div>
                        <div className="flex flex-col">
                            
                            <img src={imgDir() + '/d-te/vass/shoe.png'}  className="relative block mx-auto h-[300px] object-contain w-auto mt-8 mb-4"/>
                            <h3 className="text-center font-bold tracking-wider text-2xl">VASS SHOES</h3>
                            <p className=" text-sm zen mt-6 leading-6 grow">
                            伝説的靴職人のラズロ・ヴァーシュ氏が1978年にハンガリーで立ち上げたブランド。トラディショナルでありながらも圧倒的な存在感を放ちます。
                            </p>
                            <div className="flex justify-center mt-8">
                                <button onClick={()=>linkTo("/d-te/vass-shoes")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                    <div className="absolute inset-0 border border-black ">
                                    </div>
                                    MORE
                                    <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                    MORE
                                    </div>
                                </button>
                            </div>

                        </div>
                        <div className="flex flex-col">
                            <img src={imgDir() + '/d-te/saint_crispins/shoe.png'}  className="relative block mx-auto h-[300px] object-contain w-auto mt-8 mb-4"/>
                            <h3 className="text-center font-bold tracking-wider text-2xl">Saint Crispin's</h3>
                            <p className=" text-sm zen mt-6 leading-6 grow">
                            シューズデザイナーであるマイケル・ローリック氏によって、1992年にオーストリアで設立されたシューズブランド。
                            </p>
                            <div className="flex justify-center mt-8">
                                <button onClick={()=>linkTo("/d-te/saint-crispins")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                    <div className="absolute inset-0 border border-black ">
                                    </div>
                                    MORE
                                    <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                    MORE
                                    </div>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}