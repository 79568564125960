import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { apiUrl } from "../util";
import ArchivePost from "../Components/ArchivePost";
import SidebarNews from "../Components/SidebarNews";
import { useOnScreen } from '@ukorvl/react-on-screen';

export default function News(props: any) {
    const ref = useRef<any>(null);
    const {isOnScreen} = useOnScreen({ref});
    const [offset, setOffset] = useState(0)

    const [posts, setPosts] = useState<any>([])
    const [loading, setLoading] = useState(true)
    const [categoryId, setCategoryId] = useState<number | null>(null)

    useEffect(()=> {
        setLoading(true)
        axios.get(apiUrl() + '/wp-json/wp/v2/posts?_embed&per_page=24').then(res => {
            setLoading(false)
            setPosts(res.data)
            if (res.data.length<24) setOffset(-1)
            else setOffset(24)
        })
    }, [])

    const changeCategory = (categoryIdSrc: number | null) => {
        setCategoryId(categoryIdSrc)
        setOffset(0)
        setPosts([])
        setLoading(true)
        let url = apiUrl() + '/wp-json/wp/v2/posts?_embed&per_page=24'
        if (categoryIdSrc) {
            url += '&categories=' + categoryIdSrc
        }
        axios.get(url).then(res => {
            setPosts(res.data)
            setLoading(false)
            if (res.data.length<24) setOffset(-1)
            else setOffset(24)
        })
    }

    const loadMore = () => {
        setLoading(true)

        let url = apiUrl() + '/wp-json/wp/v2/posts?_embed&per_page=24&offset=' + offset
        if (categoryId) {
            url += '&categories=' + categoryId
        }
        axios.get(url).then(res => {
            setPosts([...posts, ...res.data])
            setLoading(false)
            if (res.data.length<24) setOffset(-1)
            else setOffset(offset+24)
        })
    }

    useEffect(() => {
        if (isOnScreen && !loading && offset!==-1) {
            loadMore()
        }
    }, [isOnScreen])

    return (
        <div className="py-20 md:py-32">
            <div className="lg:w-[940px] mx-auto px-4 lg:px-0">
                <div className="lg:flex lg:space-x-8">
                    <div className="lg:w-40 shrink-0 ">
                        <SidebarNews changeCategory={changeCategory}/>
                    </div>
                    <div className="grow mt-8 lg:mt-0">

                        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 lg:gap-8">
                            {posts.map((post: any) => (
                                <div key={post.id}>
                                    <ArchivePost post={post} />
                                </div>
                            ))}
                        </div>
                        <div ref={ref} className="text-white">{isOnScreen ? 'On screen!' : 'Not on screen'}</div>
                        {loading && (
                            <div className="flex justify-center items-center h-[50vh]">
                                <div className="animate-spin h-10 w-10 border-4 border-black rounded-full border-t-transparent"></div>
                            </div>
                        )}

                        <div className="py-10"></div>

                    </div>
                </div>
            </div>
        </div>
    )
}