import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { apiUrl, imgDir } from "../util";
import { useDispatch } from "react-redux";
import { setTransitionStarter } from "../redux/effectSlice";
import { useNavigate } from "react-router-dom";
import { useOnScreen } from '@ukorvl/react-on-screen';

export default function Blogs(props: any) {
    const [posts, setPosts] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const ref = useRef<any>(null);
    const {isOnScreen} = useOnScreen({ref});
    const [offset, setOffset] = useState(0)

    useEffect(()=>{
        setLoading(true)
        axios.get(apiUrl() + '/wp-json/wp/v2/blog?_embed&per_page=24').then(res => {
            setLoading(false)
            setPosts(res.data)
            if (res.data.length<24) setOffset(-1)
            else setOffset(24)
        })
    }, [])

    const loadMore = () => {
        setLoading(true)

        let url = apiUrl() + '/wp-json/wp/v2/blog?_embed&per_page=24&offset=' + offset
        axios.get(url).then(res => {
            setPosts([...posts, ...res.data])
            setLoading(false)
            if (res.data.length<24) setOffset(-1)
            else setOffset(offset+24)
        })
    }
    useEffect(() => {
        if (isOnScreen && !loading && offset!==-1) {
            loadMore()
        }
    }, [isOnScreen])

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }

    const thumbnail = (post: any) => {
        if(!post._embedded) return imgDir()+'/blank.png';
        else if (!post._embedded['wp:featuredmedia']) return imgDir()+'/blank.png'
        else if (!post._embedded['wp:featuredmedia'][0].media_details) return imgDir()+'/blank.png'
        else if (!post._embedded['wp:featuredmedia'][0].media_details.sizes) return imgDir()+'/blank.png'
        else if (post._embedded['wp:featuredmedia'][0].media_details.sizes.large) return  post._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url
        else if (post._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large) return  post._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url
        else if (post._embedded['wp:featuredmedia'][0].media_details.sizes.medium) return  post._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url
        else if (post._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail) return  post._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url
        else return imgDir()+'/blank.png'
    }   

    return (
        <div className="py-32">
            <div className="lg:w-[880px] mx-auto px-4 lg:px-0">
                <div className="flex justify-between items-center">
                    <h3 className="text-4xl md:text-5xl text-center lg:text-left cormorant tracking-wider uppercase">Official Blog</h3>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-8 mt-10">
                    {posts.map((post: any, i: number) =>(
                        <button onClick={()=>linkTo('/blog/'+post.id)} key={'post-'+post.id} className="flex flex-col relative group">
                            <div className="absolute inset-0 pointer-events-none bg-[rgba(0,0,0,0.1)] rounded-lg shadow-[0_0_0_1px_rgba(0,0,0,0.1)] transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:shadow-[0_0_0_10px_rgba(0,0,0,0.1)]">
                            </div>
                            <div className="w-full h-32 md:h-48">
                                <img src={thumbnail(post)} className="w-full h-full object-cover" />
                            </div>
                            <p className="grow mt-4 leading-6 text-sm font-bold text-left">{post.title.rendered}</p>

                        </button>
                    ))}
                </div>
                <div ref={ref} className="text-white">{isOnScreen ? 'On screen!' : 'Not on screen'}</div>
                {loading && (
                    <div className="flex justify-center items-center h-[50vh]">
                        <div className="animate-spin h-10 w-10 border-4 border-black rounded-full border-t-transparent"></div>
                    </div>
                )}

                <div className="py-10"></div>

            </div>
        </div>
    )
}