import React, { useState } from "react";

export default function DteTabs(props: any) {
    const [tab, setTab] = useState(0)

    return (
        <div className="relative col-span-2 py-4">
            <ul className="flex justify-center lg:justify-start space-x-8">
                <li>
                    {tab === 0? (
                        <div className="relative flex justify-center items-center pb-2 w-14 h-14 rounded-full text-3xl tracking-wider font-bold group bg-black text-white playfair">
                            01
                        </div>
                    ): (
                        <button onClick={()=>setTab(0)} className="relative block w-14 h-14 rounded-full py-2 text-3xl tracking-wider font-bold group bg-white text-black playfair">
                            <div className="absolute top-0 left-0 w-14 h-14 rounded-full border-[1.5px] border-black ">
                            </div>
                            01
                            <div className="absolute top-0 left-0 w-14 h-14 pb-2 rounded-full -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border-[1.5px] border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                01
                            </div>
                        </button>
                    )}
                </li>
                <li>
                    {tab === 1? (
                        <div className="relative flex justify-center items-center pb-2 w-14 h-14 rounded-full text-3xl tracking-wider font-bold group bg-black text-white playfair">
                            02
                        </div>
                    ): (
                        <button onClick={()=>setTab(1)} className="relative block w-14 h-14 rounded-full py-2 text-3xl tracking-wider font-bold group bg-white text-black playfair">
                            <div className="absolute top-0 left-0 w-14 h-14 rounded-full border-[1.5px] border-black ">
                            </div>
                            02
                            <div className="absolute top-0 left-0 w-14 h-14 pb-2 rounded-full -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border-[1.5px] border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                02
                            </div>
                        </button>
                    )}
                </li>
                <li>
                    {tab === 2? (
                        <div className="relative flex justify-center items-center pb-2 w-14 h-14 rounded-full text-3xl tracking-wider font-bold group bg-black text-white playfair">
                            03
                        </div>
                    ): (
                        <button onClick={()=>setTab(2)} className="relative block w-14 h-14 rounded-full py-2 text-3xl tracking-wider font-bold group bg-white text-black playfair">
                            <div className="absolute top-0 left-0 w-14 h-14 rounded-full border-[1.5px] border-black ">
                            </div>
                            03
                            <div className="absolute top-0 left-0 w-14 h-14 pb-2 rounded-full -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border-[1.5px] border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                03
                            </div>
                        </button>
                    )}
                </li>
            </ul>
            <div className="mt-8 min-h-[300px]">
                {tab == 0? (
                    <>
                        <h3 className=" font-medium text-2xl md:text-3xl text-center lg:text-left leading-10 zen">足元の靴から<br />ライフスタイルを豊かに</h3>
                        <p className="text-sm leading-8 mt-8 zen text-justify">
                            「D-TE」という名は、「地に足をつけること」を意味する「Down To Earth」の頭文字に由来しています。地に足をつけながら高いところに手を伸ばすことで、ライフスタイルは豊かになっていく。「D-TE」の名にはそんな想いが込められています。<br />
                            取り扱う商品は、どれも履き心地の良さと美しさを両立した、職人の手で作られた上質な靴。身につけることで気分まで上向きにしてくれるようなアイテムとの出会いをお手伝いします。
                        </p>
                    </>
                ): tab ===1? (
                    <>
                        <h3 className="font-medium text-2xl md:text-3xl text-center lg:text-left leading-10 zen">スーツのための靴から<br />日常を豊かにする<br className="md:hidden" />ファッションアイテムへ</h3>
                        <p className="text-sm leading-8 mt-8 zen text-justify">
                            「スーツの時にしか履かないもの」という革靴の認識を覆すべく、たとえ一級品であろうともあくまで日常を豊かにするためのファッションアイテムとして捉え、多方面からのコーディネートをご提案します。
                            身につけることで気分まで上向きにしてくれるような、そんなアイテムとの出会いをお手伝いします。
                        </p>
                    </>
                ): (
                    <>
                        <h3 className="font-medium text-2xl md:text-3xl text-center lg:text-left leading-10 zen">職人の技術が織りなす<br />美しさと履き心地を両立した靴</h3>
                        <p className="text-sm leading-8 mt-8 zen text-justify">
                            取り扱うアイテムは、靴の名産地であるハンガリーの名ブランド「VASS」をはじめ、職人たちの熟練した手縫い技術によって作られた上質な靴のみ。
                            人の手で一つひとつ仕上げているからこそ、その流麗な見た目の美しさはもちろんのこと、足にフィットする快適な履き心地を実現します。
                        </p>
                    </>
                )}

            </div>
        </div>
    )
}