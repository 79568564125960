import { createSlice } from '@reduxjs/toolkit'

export const postSlice = createSlice({
    name: 'post',
    initialState: {
        dteStaffs: [],
        centoStaffs: [],
        posts: [],
        katos: [],
        categories: []
    },
    reducers: {
        setDteStaffs: (state, action) => {
            state.dteStaffs = action.payload
        },
        setCentoStaffs: (state, action) => {
            state.centoStaffs = action.payload
        },
        setPosts: (state, action) => {
            state.posts = action.payload
        },
        setKatos: (state, action) => {
            state.katos = action.payload
        },
        setCategories: (state, action) => {
            state.categories = action.payload
        }

    }
})

export const { setDteStaffs, setCentoStaffs, setPosts, setKatos, setCategories} = postSlice.actions
export default postSlice.reducer