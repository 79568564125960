import React from "react";
import { imgDir } from "../../util";
import { useDispatch } from "react-redux";
import { setTransitionStarter } from "../../redux/effectSlice";
import { useNavigate } from "react-router-dom";

const laszloStyle = () => {
    const css = `
    #laszioTypo {
        animation: laszioAnimation 20s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
    @keyframes laszioAnimation {
        0% {
            left:0px;
        }
        100% {
            left: -2175px;
        }
    }
    `
    return (
        <style>
            {css}
        </style>
    )
}

export default function Laszlo(props: any) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }
    return (
        <>
            {laszloStyle()}
            <section className="section bg-[#F0F0F0] pt-24 lg:pt-40 pb-20 relative">
                <div className="relative mx-auto">
                    <h6 className="text-center font-bold tracking-wider">the brands we deal with</h6>
                    <div className="relative w-screen overflow-hidden">
                        <div id="laszioTypo" className="absolute top-6 left-0 flex space-x-10 text-[240px] cormorant text-white" style={{width: 1248*2*3 + 'px'}}>
                            <div className="uppercase">
                                Laszlo Budapest
                            </div>
                            <div className="uppercase">
                                Laszlo Budapest
                            </div>
                            <div className="uppercase">
                                Laszlo Budapest
                            </div>
                        </div>
                        <img src={imgDir() + '/d-te/laszlo/shoe.png'}  className="relative block mx-auto h-[300px] mt-8 mb-4"/>
                    </div>
                    <h3 className="text-center font-bold tracking-wider text-3xl">Laszlo Budapest</h3>
                    <div className="max-w-[640px] mx-auto px-4">
                        <p className=" text-sm mt-8 leading-loose zen">
                            ヨーロッパでも長らく隆盛を誇った中欧諸国。その中心たるドイツには、多くのシューファクトリーの本社が存在します。Laszlo Budapest社もその一つ。<br />
                            <br />
                            世界に誇るノイシュバンシュタイン城を眺める立地に本社を置き、製造はヨーロッパ有数の靴生産地であるハンガリーに工房を持つ、100年以上の歴史を持つ隠れた名門ファクトリー。<br />
                            <br />
                            「Laszlo（ラズロ）」の名の由来は、人の名前。東欧圏で最も多い名前を冠し、靴としての普遍性を表現しています。
                        </p>
                    </div>
                    <div className="px-4">
                        <div className="md:flex gap-4 justify-center mt-8">
                            <img alt="" className="w-full md:w-[240px] lg:w-[320px] xl:w-[423px] h-auto" src={imgDir() + '/d-te/laszlo/1@2x.jpg'} />
                            <img alt="" className="mt-4 md:mt-0 w-full md:w-[240px] lg:w-[320px] xl:w-[423px] h-auto" src={imgDir() + '/d-te/laszlo/2@2x.jpg'} />
                            <img alt="" className="mt-4 md:mt-0 w-full md:w-[240px] lg:w-[320px] xl:w-[423px] h-auto" src={imgDir() + '/d-te/laszlo/3@2x.jpg'} />
                        </div>
                    </div>
                    <div className="max-w-[640px] mx-auto px-4">
                        <p className=" text-sm mt-8 leading-loose zen">
                            他のフルブローグシューズとは一線を画す個性を持つ、<br />
                            「ブダペスター」と呼ばれる東欧において伝統的なフルブローグシューズを筆頭に、<br />
                            日本で紹介されることの少ない高品質な既製靴を生産するLaszlo。<br />
                            その工房では優秀なマイスターたちが、伝統の技術を守り続けています。
                        </p>
                    </div>
                    <div className="px-4">
                        <div className="md:flex gap-4 justify-center mt-8">
                            <img className="w-full md:w-[360px] lg:w-[423px] h-auto" src={imgDir() + '/d-te/laszlo/4@2x.jpg'} />
                            <img className="mt-4 md:mt-0 w-full md:w-[360px] lg:w-[423px] h-auto" src={imgDir() + '/d-te/laszlo/5@2x.jpg'} />
                        </div>
                    </div>
                    <div className="max-w-[640px] mx-auto px-4">
                        <p className=" text-sm mt-8 leading-loose zen">
                        そしてその中には、残念ながら今や生産国を移転してしまい、<br />
                        手縫いの技術を失ってしまったHeinrchi Dinkelackerのマイスターたちの姿も。<br />
                        Heinrchi Dinkelackerがハンガリーで受け継いでいた職人の血は、着実にこのLaszloの中に流れているのです。
                        </p>
                    </div>
                    <div className="px-4">
                        <div className="md:flex gap-4 justify-center mt-8">
                            <img className="w-full md:w-[360px] lg:w-[423px] h-auto" src={imgDir() + '/d-te/laszlo/6@2x.jpg'} />
                        </div>
                    </div>
                    <div className="max-w-[640px] mx-auto px-4">
                        <p className=" text-sm mt-8 leading-loose zen">
                        ハインリッヒディンケラッカーから受け継がれるマッシブなデザインを活かし、ユーズドデニムやパーカー、アウトドアブランドなどのカジュアルコーデにも加えてほしいブランドです。一方で「コム・デ・ギャルソン」や「ヨウジヤマモト」などのドメスティックブランドとも相性が良く、個性的な足元を求める人にも届けたい一足です。
                        </p>
                    </div>
                    <div className="px-4">
                        <div className="md:flex gap-4 justify-center mt-8">
                            <img className="w-full md:w-[360px] lg:w-[498px] h-auto" src={imgDir() + '/d-te/laszlo/7@2x.jpg'} />
                            <img className="mt-4 md:mt-0 w-full md:w-[360px] lg:w-[498px] h-auto" src={imgDir() + '/d-te/laszlo/8@2x.jpg'} />
                        </div>
                    </div>
                    <div className="flex justify-center mt-12">
                        <a href="https://centotrenta.shop-pro.jp/?mode=grp&gid=2956759&sort=n" target="_blank" rel="noreferrer" className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                            <div className="absolute inset-0 border border-black ">
                            </div>
                            商品一覧
                            <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                            商品一覧
                            </div>
                        </a>
                    </div>
                </div>
            </section>
            <section className="bg-white pt-14 pb-40">
                <div className="max-w-[740px] mx-auto px-4 lg:px-0">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8">
                        <div className="flex flex-col">
                            <img src={imgDir() + '/d-te/vass/shoe.png'}  className="relative block mx-auto h-[300px] object-contain mt-8 mb-4"/>
                            <h3 className="text-center font-bold tracking-wider text-2xl">VASS SHOES</h3>
                            <p className=" text-sm zen mt-6 leading-6 grow">
                            伝説的靴職人のラズロ・ヴァーシュ氏が1978年にハンガリーで立ち上げたブランド。トラディショナルでありながらも圧倒的な存在感を放ちます。
                            </p>
                            <div className="flex justify-center mt-8">
                                <button onClick={()=>linkTo("/d-te/vass-shoes")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                    <div className="absolute inset-0 border border-black ">
                                    </div>
                                    MORE
                                    <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                    MORE
                                    </div>
                                </button>
                            </div>

                        </div>

                        <div className="flex flex-col">
                            <img src={imgDir() + '/d-te/saint_crispins/shoe.png'}  className="relative block mx-auto h-[300px] object-contain mt-8 mb-4"/>
                            <h3 className="text-center font-bold tracking-wider text-2xl">Saint Crispin's</h3>
                            <p className=" text-sm zen mt-6 leading-6 grow">
                            シューズデザイナーであるマイケル・ローリック氏によって、1992年にオーストリアで設立されたシューズブランド。
                            </p>
                            <div className="flex justify-center mt-8">
                                <button onClick={()=>linkTo("/d-te/saint-crispins")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                    <div className="absolute inset-0 border border-black ">
                                    </div>
                                    MORE
                                    <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                    MORE
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <img src={imgDir() + '/vintage@2x.png'}  className="relative block mx-auto h-[300px] object-contain mt-8 mb-4"/>
                            <h3 className="text-center font-bold tracking-wider text-2xl">VINTAGE</h3>
                            <p className=" text-sm zen mt-6 leading-6 grow">
                            次の世代へと受け継ぐことができる…。それも革靴の大きな魅力です。履かれなくなった靴に、新たな一歩を与えてください。
                            </p>
                            <div className="flex justify-center mt-8">
                                <button onClick={()=>linkTo("/d-te/vintage")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                    <div className="absolute inset-0 border border-black ">
                                    </div>
                                    MORE
                                    <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                    MORE
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}