import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom'
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Pages/Home'
import Concept from './Pages/Concept';
import Dte from './Pages/Dte';
import CentoTrenta from './Pages/CentoTrenta';
import News from './Pages/News';
import NewsSingle from './Pages/NewsSingle';
import Blogs from './Pages/Blogs';
import BlogSingle from './Pages/BlogSingle';
import OrderSuit from './Pages/OrderSuit';
import Vass from './Pages/D_TE/Vass';
import SaintCrispins from './Pages/D_TE/SaintCrispins';
import Laszlo from './Pages/D_TE/Laszlo';
import Vintage from './Pages/D_TE/Vintage';
import TransitionCover from './Components/TransitionCover';
import { useLocation } from "react-router-dom";
import OpeningEffectShort from './Components/OpeningEffectShort';
import NotFound from './Pages/404';
import PreviewSingle from './Pages/PreviewSingle';
import BackOfficeIndex from './Pages/BackOffice/Index';
import OrderSuitCc from './Pages/OrderSuit/Cc';
import OrderSuitCs from './Pages/OrderSuit/Cs';

function App() {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const preview = query.get('preview')
  return (
    <div className='w-screen min-h-screen relative'>
      {location.pathname !== '/backoffice' && (
        <Header  />
      )}
      <Routes>
        {preview? (
              <Route path="*" element={<PreviewSingle />} />
        ): (
          <>
              <Route path="/" element={<Home />} />
              <Route path="/concept" element={<Concept />} />
              <Route path="/d-te" element={<Dte />} />
              <Route path="/d-te/vass-shoes" element={<Vass />} />
              <Route path="/d-te/laszlo-budapest" element={<Laszlo />} />
              <Route path="/d-te/saint-crispins" element={<SaintCrispins />} />
              <Route path="/d-te/vintage" element={<Vintage />} />
              <Route path="/cento-trenta" element={<CentoTrenta />} />
              <Route path="/news" element={<News />} />
              <Route path="/news/:postId" element={<NewsSingle />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blog/:postId" element={<BlogSingle />} />
              <Route path="/order-suit" element={<OrderSuit />} />
              <Route path="/order-suit/cc" element={<OrderSuitCc />} />
              <Route path="/order-suit/cs" element={<OrderSuitCs />} />

              {/* <Route path="/backoffice" element={<BackOfficeIndex />} /> */}

              <Route path="*" element={<NotFound />} />

          </>
        )}

      </Routes>
      {location.pathname !== '/backoffice' && (
        <>
          <Footer />
          <TransitionCover />
          <OpeningEffectShort />
        </>
      )}
    </div>
  );
}

export default App;
