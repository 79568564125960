// TODO: rest api のURLとimgUrlを分岐させんといかん

export function apiUrl() {
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        return 'http://localhost:8003'
    } else if (window.location.hostname === 'centotrenta.jp') {
        return 'https://' + window.location.hostname
    } else {
        return 'https://' + window.location.hostname
    }
}

export function imgDir() {
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        return 'http://localhost:8003/wp-content/themes/centotrenta/centotrenta/public/images'
    } else if (window.location.hostname === 'centotrenta.jp') {
        return 'https://' + window.location.hostname + '/wp2023/wp-content/themes/centotrenta/centotrenta/public/images'
    } else {
        return 'https://' + window.location.hostname + '/wp-content/themes/centotrenta/centotrenta/public/images'
    }
}