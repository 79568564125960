import React from "react";
import { useWindowSize } from "../useWindowSize"; 
import { imgDir } from "../util";
import { useDispatch } from "react-redux";
import { setTransitionStarter } from "../redux/effectSlice";
import { useNavigate } from "react-router-dom";
import Rules from "./OrderSuit/Rules";
import ThreeCharms from "./OrderSuit/ThreeCharms";
import OrderFlow from "./OrderSuit/OrderFlow";

const tickerStyle = (width: number) => {
    const css = `
    #ticker {
        animation: ticerAnimation 30s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
    @keyframes ticerAnimation {
        0% {
            left:0px;
        }
        100% {
            left: -${width}px;
        }
    }
    `
    return (
        <style>
            {css}
        </style>
    )
}

export default function OrderSuit(props: any) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [width, height] = useWindowSize()
    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }

    return (
        <>
            {tickerStyle(window.innerWidth<768? 140*6: 330*6)}
            {/* <div className="relative z-10 h-screen w-full flex justify-center items-center" style={{backgroundImage: `url("https://placehold.jp/640x480.png")`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                <div className="absolute top-40 left-0 w-screen">
                    <div className="container max-w-6xl mx-auto">
                        <h2 className="text-white flex items-center"><span className="inknut text-2xl tracking-wide">Chapter</span><span className="ruqaa text-6xl mb-6 ml-1">3</span></h2>
                        <h1 className="text-white font-bold">チェント・トレンタとオーダースーツ</h1>
                    </div>
                </div>
                <div className="absolute left-0 bottom-40 w-full">
                    <svg width="100" height="20" className="text-white block mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 20">
                        <polyline fill="none" stroke="currentColor" strokeWidth="2" points="1,1 50,19 99,1" />
                    </svg>
                </div>

            </div> */}
            <div className="bg-[#865925] flex h-screen justify-center items-center relative">
                <img src={imgDir() + '/suit/bg-top@2x.jpg'} alt="" className="object-cover object-top fixed top-0 left-0 w-screen h-screen" />
                <h2 className="text-6xl md:text-[160px] lg:text-[190px] playfair text-white text-center leading-relaxed md:leading-[1] relative">
                    CENTO<br />TRENTA’S<br />ORDER SUIT
                </h2>
            </div>
            <div className="bg-[#EDEDED] py-10 relative">
                <div className="max-w-[980px] mx-auto mt-10 lg:py-20">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-20">
                        <div>
                            <h3 className="font-bold text-xl md:text-3xl space-y-2">
                                <div className="bg-white inline-block px-4 py-1">チェント・トレンタが厳選する</div><br />
                                <div className="bg-white inline-block px-4 py-1">2つのオーダースーツ</div>
                            </h3>
                            <p className="text-xs leading-[30px] mt-8 tracking-wider px-4 lg:px-0">
                                チェントトレンタでは２種類の工場を使い分け、幅広いニーズにお応えできるオーダースーツ受注態勢を整えています。<br />
                                高いカスタム性とコストパフォーマンスに優れたC.Cシリーズ。<br />
                                高次元の職人技と補正力、それ由来の着心地の良さを誇るC.Sシリーズ。<br />
                                ルックス、快適性、高級感、ご予算…なんでもお気軽にご相談ください。 
                            </p>
                            <div className="px-4 lg:px-0">
                                <button onClick={()=>linkTo("/order-suit/cc")} className="bg-[#085880] relative mt-28 md:mt-40 block text-left group">
                                    <div className="w-full h-[469px] md:h-[617px] overflow-hidden">
                                        <img src={imgDir() + '/suit/cc.jpg'} alt="CC series" className="w-full h-full object-cover transition-transform duration-1000 group-hover:scale-105"/>
                                    </div>
                                    <div className="text-center text-white text-xs font-bold py-2 tracking-wider border-t border-t-white transition-colors duration-500 group-hover:text-[#085880] group-hover:bg-white">詳細を見る</div>
                                    <div className="absolute text-white pointer-events-none left-0 bottom-16 space-y-2 md:text-2xl tracking-widest">
                                        <div className="bg-[#085880] inline-block px-4 py-1">豊かなカスタム力とそれに反した</div><br />
                                        <div className="bg-[#085880] inline-block px-4 py-1">コストパフォーマンス</div>
                                    </div>
                                    <div className="absolute text-white pointer-events-none left-0 -top-16 md:-top-24 flex justify-center w-full px-6">
                                        <img src={imgDir() + '/suit/logo-cc.svg'} className="w-full h-auto"/>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className="py-10 lg:py-40 px-4 lg:px-0">
                            
                            <button onClick={()=>linkTo("/order-suit/cs")} className="bg-black relative block group ml-auto">
                                <div className="w-full h-[469px] md:h-[617px] overflow-hidden">
                                    <img src={imgDir() + '/suit/cs.jpg'} alt="CS series" className="w-full h-full object-cover transition-transform duration-1000 group-hover:scale-105"/>
                                </div>
                                <div className="text-center text-white text-xs font-bold py-2 tracking-wider border-t border-t-white transition-colors duration-500 group-hover:text-black group-hover:bg-white">詳細を見る</div>
                                <div className="absolute text-white pointer-events-none right-0 bottom-16 space-y-2 md:text-2xl tracking-widest text-right">
                                    <div className="bg-black inline-block px-4 py-1">日本で屈指の工場が作る</div><br />
                                    <div className="bg-black inline-block px-4 py-1">最高品質の着心地とデザイン</div>
                                </div>
                                <div className="absolute text-white pointer-events-none left-0 -top-16 md:-top-24 flex justify-center w-full px-8">
                                    <img src={imgDir() + '/suit/logo-cs.svg'} className="w-full h-auto"/>
                                </div>
                                
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative w-screnn overflow-hidden h-[200px] md:h-[500px]">
                <div id="ticker" className="absolute top-0 left-0 flex w-[1680px] md:w-[3960px]">
                    {[1,2,3,4,5,6,1,2,3,4,5,6].map((i,j)=>(
                    <img 
                        key={j}
                        src={imgDir() + '/suit/ticker-1-'+i+'.jpg'} 
                        srcSet={`${imgDir() + '/suit/ticker-1-'+i+'.jpg'} 767w, ${imgDir() + '/suit/ticker-1-'+i+'@2x.jpg'} 768w`} 
                        alt=""
                        className="w-[140px] md:w-[330px] h-[200px] md:h-[500px] object-cover"
                    />
                    ))}
                </div>
            </div>
            <ThreeCharms />
            <Rules />
            <OrderFlow />
            {/* <div className="bg-[#EDEDED]  pt-10 pb-20">
                <h3 className="text-[180px] playfair text-white text-center leading-[1]">
                    YOUR TYPE
                </h3>
                <h4 className="text-center mt-20"><span className="bg-white inline-block px-2 py-1 text-2xl font-bold tracking-widest">あなたに合うスーツ</span></h4>
                <p className="text-lg mt-10 font-bold text-center tracking-wider">あなたに合うスーツタイプを見つける3つの質問</p>
            </div> */}
            <div className="bg-white relative">
                <div className="py-20 flex justify-center px-4 lg:px-0">
                    <button type="button" onClick={()=>linkTo("/cento-trenta#shop")} className="block transition-opacity hover:opacity-70">
                        <img src={imgDir() + '/suit/shop-info_pc.jpg'} alt="stores" className="hidden lg:block w-[800px] h-auto" />
                        <img src={imgDir() + '/d-te/shop-info_mobile.jpg'} alt="stores" className="lg:hidden w-full h-auto" />
                    </button>
                </div>
            </div>
            <div className="bg-white md:py-20 relative">
                <div className="md:w-[700px] mx-auto">
                    <div className="md:grid grid-cols-2 gap-4">
                        <div>
                            <button onClick={()=>linkTo("/cento-trenta")} className="h-[250px] md:h-[528px] flex w-full justify-center items-center relative group" >
                                <img className="md:hidden absolute top-0 left-0 w-full h-full object-cover" src={imgDir() + '/top/chapter1bw-mobile.jpg'}/>

                                <img className="hidden md:block absolute top-0 left-0 w-full h-full object-cover" src={imgDir() + '/top/chapter1bw.jpg'}/>
                                <img className="hidden md:block absolute top-0 left-0 w-full h-full object-cover opacity-0 transition-opacity group-hover:opacity-100 duration-500" src={imgDir() + '/top/chapter1.jpg'}/>
                                <div className="relative">
                                    <h5 className="text-white flex justify-center items-center"><span className="inknut text-2xl md:text-4xl tracking-wide">Chapter</span><span className="ruqaa text-6xl md:text-7xl mb-5 ml-2">1</span></h5>
                                    <h4 className="text-white text-center mt-4 font-bold">はじまり<br />と<br />CENTO TRENTA</h4>
                                </div>
                            </button>
                        </div>
                        <div>
                            <button onClick={()=>linkTo("/d-te")} className="h-[250px] md:h-[528px] flex w-full justify-center items-center relative group" >
                                <img className="md:hidden absolute top-0 left-0 w-full h-full object-cover" src={imgDir() + '/top/chapter2bw-mobile.jpg'}/>

                                <img className="hidden md:block absolute top-0 left-0 w-full h-full object-cover" src={imgDir() + '/top/chapter2bw.jpg'}/>
                                <img className="hidden md:block absolute top-0 left-0 w-full h-full object-cover opacity-0 transition-opacity group-hover:opacity-100 duration-500" src={imgDir() + '/top/chapter2.jpg'}/>
                                <div className="relative">
                                    <h5 className="text-white flex justify-center items-center"><span className="inknut text-2xl md:text-4xl tracking-wide">Chapter</span><span className="ruqaa text-6xl md:text-7xl mb-5 ml-2">2</span></h5>
                                    <h4 className="text-white text-center mt-4 font-bold">新たなステージ<br />と<br />D-TE SHOE STORE</h4>
                                </div>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            <div className="h-[100px] bg-white relative">

            </div>
        </>
    )
}