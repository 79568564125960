import React from "react";
import { useDispatch } from "react-redux";
import { setTransitionStarter } from "../redux/effectSlice";
import { useNavigate } from "react-router-dom";

export default function NotFound(props: any) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }
    return (
        <>
            <div className="relative h-screen w-full flex justify-center items-center">
                <div>
                    <h1 className="text-3xl md:text-5xl tracking-wide uppercase cormorant text-center">Page not found...</h1>
                    <p className="text-center mt-10 leading-8 px-4">
                        お探しのページは見つかりませんでした。<br />
                        URLが間違っているか、ページの公開期限を過ぎてしまった可能性があります。<br />
                    </p>
                    <div className="text-center mt-10">
                        <button onClick={()=>linkTo('/')} className="d-inline-block mt-10 bg-black text-white px-6 py-2 transition-colors hover:bg-gray-800">TOP</button>
                    </div>
                </div>
            </div>
        
        </>
    )
}