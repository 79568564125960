import React from "react";
import { MobileView, BrowserView } from "react-device-detect";
import DtePc from "./D_TE/Pc";
import DteMobile from "./D_TE/Mobile";

export default function Dte(props: any) {
    return (
        <>
        <BrowserView>
            <DtePc />
        </BrowserView>
        <MobileView>
            <DteMobile />
        </MobileView>
        </>
    )
}