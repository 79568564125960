import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../util";
import { useParams, useNavigate } from "react-router-dom";

export default function NewsSingle(props: any) {
    const [post, setPost] = useState<any | null>(null)
    const { postId } = useParams()
    const navigate = useNavigate()
    useEffect(()=>{
        axios.get(apiUrl() + '/wp-json/wp/v2/posts/'+ postId).then(res => {
            console.log('post', res.data)
            if (!res.data) {
                navigate('/news')
            } else {
                setPost(res.data)
            }
        }).catch(error => {
            navigate('/news')
        })
    }, [])
    return (
        <div className="py-32">
            <div className="lg:w-[640px] mx-auto px-6 lg:px-0 single-content">
                {post && (
                    <>
                        <h1  dangerouslySetInnerHTML={{__html: post.title.rendered}} className="text-2xl" ></h1>
                        <div dangerouslySetInnerHTML={{__html: post.content.rendered}} className=" mt-8 text-xs leading-6">
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}