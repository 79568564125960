import React from "react";
import { imgDir } from "../../util";
import { useDispatch } from "react-redux";
import { setTransitionStarter } from "../../redux/effectSlice";
import { useNavigate } from "react-router-dom";

const saintStyle = () => {
    const css = `
        #saintTypo {
            animation: saintAnimation 20s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
        @keyframes saintAnimation {
            0% {
                left:0px;
            }
            100% {
                left: -1888px;
            }
        }
    `
    return (
        <style>
            {css}
        </style>
    )
}

export default function SaintCrispins(props: any) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }
    return (
        <>
            {saintStyle()}
            <section className="section bg-[#F0F0F0] pt-24 lg:pt-40 pb-20 relative">
                <div className="relative mx-auto">
                    <h6 className="text-center font-bold tracking-wider">the brands we deal with</h6>
                    <div className="relative w-screen overflow-hidden">
                        <div id="saintTypo" className="absolute top-6 left-0 flex space-x-10 text-[240px] cormorant text-white" style={{width: 1248*2*3 + 'px'}}>
                            <div className="uppercase">
                            Saint Crispin's
                            </div>
                            <div className="uppercase">
                            Saint Crispin's
                            </div>
                            <div className="uppercase">
                            Saint Crispin's
                            </div>

                        </div>
                        <img src={imgDir() + '/d-te/saint_crispins/shoe.png'}  className="relative block mx-auto h-[300px] mt-8 mb-4"/>
                    </div>
                    <h3 className="text-center font-bold tracking-wider text-3xl">Saint Crispin's</h3>
                    <div className="max-w-[640px] mx-auto px-4">
                        <p className=" text-sm mt-8 leading-loose zen">
                            シューズデザイナーであるマイケル・ローリック氏によって、1992年に設立されたシューズブランド。<br />
                            <br />
                            芸術と文化の国であるオーストリアで産声を上げたこのブランドは、現代ではその生産工程の非効率さからほとんど廃れたと言ってもいいような技術をふんだんに使いながらも、そのデザインは美麗の一言に尽きる美しさを誇り、職人技の粋と圧倒的なデザイン性の兼備という類稀な両立を実現することで、靴業界の中でも独自の地位を築き上げています。
                        </p>
                    </div>
                    <div className="max-w-[880px] mx-auto px-4">
                        <div className="md:flex gap-4 justify-center mt-8">
                            <img className="w-full md:w-[360px] lg:w-[423px] h-auto" src={imgDir() + '/d-te/saint_crispins/1@2x.jpg'} />
                            <img className="mt-4 md:mt-0 w-full md:w-[360px] lg:w-[423px] h-auto" src={imgDir() + '/d-te/saint_crispins/2@2x.jpg'} />
                        </div>
                    </div>
                    <div className="max-w-[640px] mx-auto px-4">
                        <p className=" text-sm mt-8 leading-loose zen">
                            生産地であるルーマニアの工場には、たった十数人の熟練工が月産80足という丁寧ながらも極小のロットで生産を続けています。その作業は緻密かつ芸術的。<br />
                            Saint crispin’sそのものを表すような生産工程は、そのほとんどを手作業に依拠しているのです。
                        </p>
                        <div className="md:px-16">
                            <img className="w-[423px] h-auto mt-8" src={imgDir() + '/d-te/saint_crispins/3@2x.jpg'} />
                        </div>
                        <p className=" text-sm mt-8 leading-loose zen">
                            流麗な見た目からは想像もつかないような快適な履き心地は、圧倒的技術のなせる技。<br />
                            足を入れられる美術品と呼んで然るべき、宝石のような靴たちです。
                        </p>
                        <div className="md:px-16">
                            <img className="w-[423px] h-auto mt-8" src={imgDir() + '/d-te/saint_crispins/4@2x.jpg'} />
                        </div>
                        <p className=" text-sm mt-8 leading-loose zen">
                            ジャケットやシャツなど、ドレッシーなアイテムを使ったワンランク上のカジュアルコーデや、ラギッドなアイテムを混ぜ合わせたボーダレスなコーデに加えてほしいブランドです。
                        </p>

                    </div>
                    <div className="max-w-[880px] mx-auto px-4">
                        <div className="md:flex gap-4 justify-center mt-8">
                            <img className="w-full md:w-[360px] lg:w-[423px] h-auto" src={imgDir() + '/d-te/saint_crispins/5@2x.jpg'} />
                            <img className="mt-4 md:mt-0 w-full md:w-[360px] lg:w-[423px] h-auto" src={imgDir() + '/d-te/saint_crispins/6@2x.jpg'} />
                        </div>
                    </div>
                    <div className="flex justify-center mt-12">
                        <a href="https://centotrenta.shop-pro.jp/?mode=grp&gid=2956760&sort=n" target="_blank" rel="noreferrer" className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                            <div className="absolute inset-0 border border-black ">
                            </div>
                            商品一覧
                            <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                            商品一覧
                            </div>
                        </a>
                    </div>
                </div>
            </section>
            <section className="bg-white pt-14 pb-40">
                <div className="max-w-[740px] mx-auto px-4 lg:px-0">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8">
                        <div className="flex flex-col">
                            <img src={imgDir() + '/d-te/laszlo/shoe.png'}  className="relative block mx-auto h-[300px] object-contain mt-8 mb-4"/>
                            <h3 className="text-center font-bold tracking-wider text-2xl">Laszlo Budapest</h3>
                            <p className=" text-sm zen mt-6 leading-6 grow">
                                ヨーロッパ有数の靴生産地であるハンガリーで100年以上の歴史を持つ、隠れた名門。ハンドメイドの靴作りの技術を、職人たちが今も守り続けています。
                            </p>
                            <div className="flex justify-center mt-8">
                                <button onClick={()=>linkTo("/d-te/laszlo-budapest")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                    <div className="absolute inset-0 border border-black ">
                                    </div>
                                    MORE
                                    <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                    MORE
                                    </div>
                                </button>
                            </div>

                        </div>
                        <div className="flex flex-col">
                            <img src={imgDir() + '/d-te/vass/shoe.png'}  className="relative block mx-auto h-[300px] object-contain mt-8 mb-4"/>
                            <h3 className="text-center font-bold tracking-wider text-2xl">VASS SHOES</h3>
                            <p className=" text-sm zen mt-6 leading-6 grow">
                            伝説的靴職人のラズロ・ヴァーシュ氏が1978年にハンガリーで立ち上げたブランド。トラディショナルでありながらも圧倒的な存在感を放ちます。
                            </p>
                            <div className="flex justify-center mt-8">
                                <button onClick={()=>linkTo("/d-te/vass-shoes")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                    <div className="absolute inset-0 border border-black ">
                                    </div>
                                    MORE
                                    <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                    MORE
                                    </div>
                                </button>
                            </div>

                        </div>
                        <div className="flex flex-col">
                            <img src={imgDir() + '/vintage@2x.png'}  className="relative block mx-auto h-[300px] object-contain mt-8 mb-4"/>
                            <h3 className="text-center font-bold tracking-wider text-2xl">VINTAGE</h3>
                            <p className=" text-sm zen mt-6 leading-6 grow">
                            次の世代へと受け継ぐことができる…。それも革靴の大きな魅力です。履かれなくなった靴に、新たな一歩を与えてください。
                            </p>
                            <div className="flex justify-center mt-8">
                                <button onClick={()=>linkTo("/d-te/vintage")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                    <div className="absolute inset-0 border border-black ">
                                    </div>
                                    MORE
                                    <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                    MORE
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}