import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { apiUrl } from "../util";
import { setCategories } from "../redux/postSlice";

export default function SidebarNews(props: any) {
    const dispatch = useDispatch()
    const categories = useSelector((state: any) => state.posts.categories)
    const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null)

    useEffect(()=>{
        if (!categories.length) {
            axios.get(apiUrl() + '/wp-json/wp/v2/categories').then(res => {
                // console.log('categories', res.data)
                dispatch(setCategories(res.data))
            })
        }
    }, [])

    const onClickCategory = (categoryId: number | null) => {
        setSelectedCategoryId(categoryId)
        props.changeCategory(categoryId)
    }

    return (
        <ul className="flex lg:block flex-wrap justify-center lg:space-y-6">
            <li className="m-2 lg:m-0">
                {!selectedCategoryId? (
                        <div className="bg-black text-white text-xs py-2 text-center w-28 lg:w-full">
                            ALL
                        </div>
                    ):(
                        <button onClick={()=>onClickCategory(null)} className=" text-xs relative block  w-28 lg:w-full py-2 tracking-wider font-bold group bg-white text-black">
                            <div className="absolute inset-0 border border-black ">
                            </div>
                            ALL
                            <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                            ALL
                            </div>
                        </button>
                    )}
            </li>
            {categories.map((category: any) => (
                <li key={'category-'+ category.id}  className="m-2 lg:m-0">
                    {category.id === selectedCategoryId? (
                        <div className="bg-black text-white w-28 lg:w-full text-xs py-2 text-center">
                            {category.name}
                        </div>
                    ):(
                        <button onClick={()=>onClickCategory(category.id)} className=" text-xs relative block  w-28 lg:w-full py-2 tracking-wider font-bold group bg-white text-black">
                            <div className="absolute inset-0 border border-black ">
                            </div>
                            {category.name}
                            <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                            {category.name}
                            </div>
                        </button>
                    )}

                </li>
            ))}
        </ul>
    )
}