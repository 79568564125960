import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl, imgDir } from "../util";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { setPosts } from "../redux/postSlice";
import { setTransitionStarter } from "../redux/effectSlice";
import { useNavigate } from "react-router-dom";

export default function OfficialBlog(props: any) {
    const dispatch = useDispatch()
    const posts = useSelector((state: any) => state.posts.posts)
    const { ref, inView } = useInView({
        threshold: 0
    })
    useEffect(() => {
        if (inView && !posts.length)
        axios.get(apiUrl() + '/wp-json/wp/v2/blog?_embed&per_page=4').then(res => {
            // setPosts(res.data)
            dispatch(setPosts(res.data))
        })
    }, [inView])
    const navigate = useNavigate()
    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }
    const thumbnail = (post: any) => {
        if(!post._embedded) return imgDir()+'/blank.png';
        else if (!post._embedded['wp:featuredmedia']) return imgDir()+'/blank.png'
        else if (!post._embedded['wp:featuredmedia'][0].media_details) return imgDir()+'/blank.png'
        else if (!post._embedded['wp:featuredmedia'][0].media_details.sizes) return imgDir()+'/blank.png'
        else if (post._embedded['wp:featuredmedia'][0].media_details.sizes.large) return  post._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url
        else if (post._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large) return  post._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url
        else if (post._embedded['wp:featuredmedia'][0].media_details.sizes.medium) return  post._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url
        else if (post._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail) return  post._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url
        else return imgDir()+'/blank.png'
    }   

    return (
        <div ref={ref} className="py-20">
            <div className="lg:w-[880px] mx-auto px-4 lg:px-0">
                <div className="flex justify-center md:justify-between items-center">
                    <h3 className="text-4xl md:text-5xl cormorant tracking-wider uppercase">Official Blog</h3>
                    <button onClick={()=>linkTo("/blogs")} className="relative hidden md:block w-48 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                        <div className="absolute inset-0 border border-black ">
                        </div>
                        一覧を見る
                        <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                        一覧を見る
                        </div>
                    </button>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-8 mt-10 md:h-72">
                    {posts.map((post: any, i: number) =>(
                        <button onClick={()=>linkTo('/blog/'+post.id)} key={'post-'+post.id} className={`block relative group ${i>=3? 'md:hidden': ''}`}>
                            <div className="absolute inset-0 pointer-events-none bg-[rgba(0,0,0,0.1)] rounded-lg shadow-[0_0_0_1px_rgba(0,0,0,0.1)] transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:shadow-[0_0_0_10px_rgba(0,0,0,0.1)]">
                            </div>
                            <div className="w-full h-32 md:h-48">
                                <img src={thumbnail(post)} className="w-full h-full object-cover" />
                            </div>
                            <p className="mt-4 leading-6 text-sm font-bold">{post.title.rendered}</p>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    )
}