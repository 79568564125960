import axios from "axios";
import React, { useEffect, useState } from "react";
import DropDown from "./DropDown";
import { apiUrl, imgDir } from "../../util";
import { useInView } from "react-intersection-observer";
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { MobileView, BrowserView } from "react-device-detect";
import ActionSheet from "./ActionSheet";

const taxonomies = [
    {
        title: '形',
        slug: 'feet_shape'
    },
    {
        title: 'ブランド',
        slug: 'feet_brand'
    },
    {
        title: '靴下',
        slug: 'socks'
    },
    {
        title: 'パンツ',
        slug: 'pants'
    },
    {
        title: '革',
        slug: 'leather'
    },
    {
        title: 'TPO',
        slug: 'tpo'
    },
]

export default function VariousFeets(props: any) {
    const [loading, setLoading] = useState(false)
    const { ref, inView } = useInView({
        threshold: 0
    })
    const [swiper, setSwiper] = useState<any>(null)

    const [posts, setPosts] = useState([])
    const [selectedTax, setSelectedTax] = useState('')

    useEffect(()=>{
        if (inView && !posts.length) {
            updatePosts(null, null)
        }
    }, [inView])

    const updatePosts = (taxonomy: string|null, taxId: number|null) => {
        setPosts([])
        setLoading(true)
        if (taxonomy && taxId) {
            axios.get(apiUrl() + '/wp-json/wp/v2/feet?'+taxonomy+'='+taxId+'&_embed').then(res => {
                // console.log('feet', res.data)
                setLoading(false)
                setPosts(res.data)
            })
        } else {
            axios.get(apiUrl() + '/wp-json/wp/v2/feet?_embed').then(res => {
                // console.log('feet', res.data)
                setLoading(false)
                setPosts(res.data)
            })
        }

    }
    const onClickNext = () => {
        // props.slideNext()
        swiper.slideNext()
    }
    const onClickPrev = () => {
        // props.slidePrev()
        swiper.slidePrev()
    }

    const onChangeTaxonomy = (slug: string, id: number| null) => {
        updatePosts(slug, id)
        setSelectedTax(slug)
    }

    const thumbnail = (post: any) => {
        if(!post._embedded) return imgDir()+'/blank.png';
        else if (!post._embedded['wp:featuredmedia']) return imgDir()+'/blank.png'
        else if (!post._embedded['wp:featuredmedia'][0].media_details) return imgDir()+'/blank.png'
        else if (!post._embedded['wp:featuredmedia'][0].media_details.sizes) return imgDir()+'/blank.png'
        else if (post._embedded['wp:featuredmedia'][0].media_details.sizes.large) return  post._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url
        else if (post._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large) return  post._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url
        else if (post._embedded['wp:featuredmedia'][0].media_details.sizes.medium) return  post._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url
        else if (post._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail) return  post._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url
        else return imgDir()+'/blank.png'
    }   

    return (
        <>
        <BrowserView>
        <div className="max-w-[880px] mx-auto pb-10">
            <ul className="flex justify-around">
                {taxonomies.map((taxonomy: any, i: number) => (
                    <li key={i}>
                        <DropDown taxonomy={taxonomy} onChangeTaxonomy={onChangeTaxonomy} selectedTax={selectedTax}/>
                    </li>
                ))}
            </ul>
        </div>
        </BrowserView>
        <MobileView>
            <div className="w-screen overflow-scroll pb-10 mt-6">
                <div className="px-8 w-[800px]">
                    <ul className="flex justify-around">
                        {taxonomies.map((taxonomy: any, i: number) => (
                            <li key={i}>
                                <ActionSheet taxonomy={taxonomy} onChangeTaxonomy={onChangeTaxonomy} selectedTax={selectedTax} />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </MobileView>
        <div ref={ref} className="h-[400px] w-screen overflow-hidden">
            {loading && (
                <div className="flex justify-center items-center h-[400px]">
                    <div className="animate-spin h-10 w-10 border-4 border-black rounded-full border-t-transparent"></div>
                </div>
            )}

            {posts.length && (
                <Swiper
                    spaceBetween={20}
                    slidesPerView={'auto'}
                    onSlideChange={() => console.log('slide change')}
                    className="lg:!overflow-visible"
                    initialSlide={1}
                    centeredSlides={window.innerWidth > 576}
                    onSwiper={(swiper) => setSwiper(swiper)}

                >
                    {posts.map((post: any, i: number) => (
                        <SwiperSlide key={i} className="!w-[calc(100vw-80px)] md:!w-[340px]">
                            <div className="relative h-[400px] w-full">
                                <img src={thumbnail(post)} className="w-full h-full object-cover"/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}

        </div>
        <div className="max-w-[880px] mx-auto mt-10">
            <div className="flex justify-between">
                <button onClick={()=>onClickPrev()} className="p-2 rounded hover:bg-[rgba(0,0,0,0.1)] transition-colors">
                    <svg width="120" height="16" className="block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 16">
                        <polyline fill="none" stroke="currentColor" strokeWidth="2" points="20,4 1,15 119,15" />
                    </svg>
                </button>
                <button onClick={()=>onClickNext()} className="p-2 rounded hover:bg-[rgba(0,0,0,0.1)] transition-colors">
                    <svg width="120" height="16" className="block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 16">
                        <polyline fill="none" stroke="currentColor" strokeWidth="2" points="1,15 119,15 100,4" />
                    </svg>
                </button>
            </div>
        </div>

        </>
    )
}