import React from "react";
import { imgDir } from "../util";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { setTransitionStarter } from "../redux/effectSlice";
import { useNavigate } from "react-router-dom";

export default function ArchivePost(props: any) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }
    const thumbnail = (post: any) => {
        if(!post._embedded) return imgDir()+'/blank.png';
        else if (!post._embedded['wp:featuredmedia']) return imgDir()+'/blank.png'
        else if (!post._embedded['wp:featuredmedia'][0].media_details) return imgDir()+'/blank.png'
        else if (!post._embedded['wp:featuredmedia'][0].media_details.sizes) return imgDir()+'/blank.png'
        else if (post._embedded['wp:featuredmedia'][0].media_details.sizes.large) return  post._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url
        else if (post._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large) return  post._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url
        else if (post._embedded['wp:featuredmedia'][0].media_details.sizes.medium) return  post._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url
        else if (post._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail) return  post._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url
        else return imgDir()+'/blank.png'
    }   

    return (
        <button onClick={()=>linkTo('/news/'+props.post.id)} className="relative block group text-left">
            <div className="w-full h-32 md:h-40 relative bg-white">
                <img src={thumbnail(props.post)} className="w-full h-full object-cover" />
            </div>
            <div className="mt-2 text-gray-500 text-xs">{format(new Date(props.post.date), 'yyyy.MM.dd')}</div>
            <h5 className="mt-2 md:leading-6 text-xs md:text-sm">{props.post.title.rendered}</h5>
            <span className="absolute top-0 left-0 text-center text-xs  bg-white border border-black py-1 px-2 block min-w-[8rem] font-bold">{props.post._embedded['wp:term'][0][0].name}</span>
            <div className="absolute inset-0 bg-[rgba(0,0,0,0.05)] rounded transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:shadow-[0_0px_0px_10px_rgba(0,0,0,0.05)]"></div>
        </button>
    )
}