import React from "react";
import { BrowserView, MobileView } from "react-device-detect"
import HomePc from "./Home/Pc";
import HomeMobile from "./Home/Mobile";

export default function HomeIndex(props: any) {

    return (
        <>
            <BrowserView>
                <HomePc />
            </BrowserView>
            <MobileView >
                <HomeMobile />
            </MobileView>
        </>
    )
}