import React from "react";
import { imgDir } from "../util";
import { MobileView } from "react-device-detect";
import MobileMenu from "./MobileMenu";
import { useDispatch } from "react-redux";
import { setTransitionStarter } from "../redux/effectSlice";
import { useNavigate } from "react-router-dom";

export default function Header(props: any) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }
    return (
        <>
            <div className="pointer-events-none fixed inset-0 z-30" style={{backgroundImage: 'url('+ imgDir() +'/bg-header-lg.svg)', backgroundSize: 'cover', backgroundPosition: 'top'}}>
            </div>
            <button onClick={()=>linkTo("/")} className="fixed top-1 2xl:top-4 left-1 2xl:left-4 p-2 lg:p-4 z-30 text-white">
                <svg className="w-8 lg:w-16 h-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.61 89.18">
                    <path fill="currentColor" d="M47.27,53.86C17.7,72.96-1.22,33.6,16.67,11.28,25.48,1.94,42.39,2.87,49.88,13.41l2.19-9.06C-4.72-19.35-20.55,61.39,32.3,62.9c6.27-.38,13.76-.96,18.51-5.18,0,0,.33-6.82,.33-6.82-1.09,.98-2.37,1.89-3.88,2.97Z"/>
                    <path fill="currentColor" d="M71.19,28.45c-11.2,0-23.6-.03-25.42-.08v6.67c1.2-.28,15.17-1.03,21.36-.91,.41,17.89,.44,37.22-.43,55.06h9c-.87-17.84-.85-37.17-.44-55.06,6.2-.11,20.15,.63,21.36,.91v-6.67c-1.82,.06-14.22,.08-25.42,.08Z"/>
                </svg>
            </button>
            <a href="https://www.instagram.com/centotrentaofficial/" target="_blank" className="fixed top-6 right-4 p-4 text-white z-30 hidden lg:block">
                <svg className="w-8 h-8 " viewBox="0 0 448 512">
                    {/* <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */}
                    <path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                </svg>
            </a>
            <ul className="hidden lg:block fixed left-0 top-[calc(50vh+100px)] space-y-2 z-40">
                <li>
                    <a href="https://centotrenta.jp/recruit/" target="_blank" className="inline-block bg-black text-white py-1 px-4">RECRUIT</a>
                </li>
                <li>
                    <a href="https://centotrenta.shop-pro.jp/" target="_blank" className="inline-block bg-black text-white py-1 px-4">ONLINE SHOP</a>
                </li>
            </ul>
            <MobileView>
                <MobileMenu />
            </MobileView>
        </>
    )
}