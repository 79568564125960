import axios from "axios";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { setKatos } from "../redux/postSlice";
import { apiUrl, imgDir } from "../util";

export default function IsaoKatoBlog(props: any) {
    const dispatch = useDispatch()
    const katos = useSelector((state: any) => state.posts.katos)
    const { ref, inView } = useInView({
        threshold: 0
    })

    useEffect(()=>{
        if (inView && !katos.length) {
            axios.get(apiUrl() + '/wp-json/wp/custom/ameblo').then(res => {
                // console.log('ameblo', res.data)
                dispatch(setKatos(res.data.channel.item))
            })
        }
    }, [inView])
    return (
        <div ref={ref} className="py-20">
            <div className="lg:w-[880px] mx-auto px-4 lg:px-0">
                <div className="flex justify-center md:justify-between items-center">
                    <h3 className="text-4xl md:text-5xl cormorant tracking-wider uppercase text-center md:text-left">ISAO KATO'S BLOG</h3>
                    <a href="https://ameblo.jp/cera-una/" target="_blank" className="relative hidden md:block w-48 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                        <div className="absolute inset-0 border border-black ">
                        </div>
                        一覧を見る
                        <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                        一覧を見る
                        </div>
                    </a>
                </div>

                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-8 mt-10 md:h-72">
                    {katos.map((post: any, index: number) => (
                        <a key={'isao-'+index} href={post.link} target="_blank" className={`block relative group ${index>=3? 'md:hidden': ''} ${index>=4? 'hidden': ''}`}>
                            <div className="w-full h-32 md:h-48">
                                <img src={imgDir() + '/blank.png'} className="w-full h-full object-contain" />
                            </div>
                            <p className="mt-4 leading-6 text-sm font-bold">{post.title}</p>
                            <div className="absolute inset-0 pointer-events-none bg-[rgba(0,0,0,0.1)] rounded-lg shadow-[0_0_0_1px_rgba(0,0,0,0.1)] transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:shadow-[0_0_0_10px_rgba(0,0,0,0.1)]">
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}