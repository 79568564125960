import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../util";

export default function ActionSheet(props: any) {
    const [collapsed, setCollapsed] = useState(false)
    const [items, setItems] = useState([])
    const [selectedId, setSelectedId] = useState<number|null>(null)
    const [active, setActive] = useState(false)
    const onClickTaxonomy = () => {
        if (collapsed) {
            // 開いていたら、閉じるだけ
            setActive(false)
            return 
        }
        if (items.length) {
            // items取得済みの場合は開くだけ
            setCollapsed(true)
            return
        }
        axios.get(apiUrl() + '/wp-json/wp/v2/'+props.taxonomy.slug).then(res => {
            console.log(res.data)
            setItems(res.data)
            setCollapsed(true)
        })
    }
    useEffect(()=>{
        if (props.selectedTax !== props.taxonomy.slug) {
            // 選択解除
            setSelectedId(null)
        }
    }, [props.selectedTax])

    const onClickItem = (itemId: number) => {
        if (selectedId === itemId) {
            setSelectedId(null)
            props.onChangeTaxonomy(props.taxonomy.slug, null)
        } else {
            setSelectedId(itemId)
            props.onChangeTaxonomy(props.taxonomy.slug, itemId)
        }
        // 閉じる
        onClickTaxonomy()
    }

    useEffect(()=>{
        if (collapsed) {
            setTimeout(()=>{
                setActive(true)
            }, 100)
        }
    }, [collapsed])

    useEffect(()=>{
        if (!active) {
            setTimeout(()=>{
                setCollapsed(false)
            }, 100)
        }
    }, [active])

    return (
        <>
        <div className={`fixed top-0 left-0 w-screen h-screen z-30 flex justify-center items-center ${collapsed? '': 'hidden'}`}>
            <div className={`fixed top-0 left-0 w-screen h-screen z-10 bg-[rgba(0,0,0,0.4)] transition-opacity ${collapsed? '': 'hidden'} ${active? 'opacit-100': 'opacity-0'}`} onClick={()=>setActive(false)}>
            </div>
            <ul className={`border-t-2 border-x-2 border-black bg-white zen relative z-30 transition-all duration-300 ${active? 'opacity-100 scale-100': 'opacity-0 scale-110'}`}>
                {items.map((item: any, i: number) => (
                    <li key={i} className="border-b-2 border-black">
                        <button onClick={()=>onClickItem(item.id)} className="hover:bg-[rgba(0,0,0,0.1)] transition-colors px-4 py-2 flex items-center w-full min-w-[280px] text-sm whitespace-nowrap">
                            {selectedId === item.id && (
                                <svg className="w-5 h-5 mr-1" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                </svg>
                            )}
                            {item.name}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
        <div className={`relative ${collapsed? 'z-20': ''}`}>
            <button onClick={()=>onClickTaxonomy()} className="zen block">
                <div className="py-1 px-2 text-sm">
                    {props.taxonomy.title}から選ぶ
                </div>
                <div className="border-t-2 border-t-black"></div>
                <div className="pt-1">
                    <svg className="w-3 h-3 text-black block mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <polyline fill="currentColor" points="1,2 10,17 19,2" />
                    </svg>
                </div>
            </button>
        </div>
        
        </>
    )
}