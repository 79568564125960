import { createSlice } from '@reduxjs/toolkit'

export const effectSlice = createSlice({
    name: 'effect',
    initialState: {
        transitionStarter: false,
        opened: false   // オープニングエフェクト表示した？
    },
    reducers: {
        setTransitionStarter: (state, action) => {
            state.transitionStarter = action.payload
        },
        setOpened: (state, action) => {
            state.opened = action.payload
        }
    }
})

export const { setTransitionStarter, setOpened } = effectSlice.actions
export default effectSlice.reducer