import { useNavigate } from "react-router-dom";
import { setTransitionStarter } from "../../redux/effectSlice";
import { imgDir } from "../../util";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const tickerStyle = (width: number) => {
    const css = `
    #ticker,#ticker-sm {
        animation: ticerAnimation 20s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
    @keyframes ticerAnimation {
        0% {
            left:0px;
        }
        100% {
            left: -${width}px;
        }
    }
    `
    return (
        <style>
            {css}
        </style>
    )
}

export default function OrderSuitCs(props: any) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }
    useEffect(() => {
        setTimeout(()=>{
            // #ticker-0のclassから .hidden を削除
            document.getElementById('ticker-0')?.classList.remove('opacity-0')
        }, 10000)

    }, [])
    return (
        <>
        {tickerStyle(window.innerWidth<768? 180*3: window.innerWidth<1024? 420*3: 600*3)}

        <div className="bg-[#4B3A28] text-white">
            <button onClick={()=>linkTo("/order-suit")} className="fixed top-16 md:top-44 left-0 flex items-center bg-white py-1 md:py-2 pl-2 md:pl-4 pr-4 md:pr-8 z-10 text-black border-b-2 border-b-black playfair tracking-wider text-xs md:text-base">
                <svg className="w-5 h-5 rotate-180 mr-2 md:mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>play</title><path d="M8,5.14V19.14L19,12.14L8,5.14Z" /></svg>
                Page Back
            </button>

            <img src={imgDir() + '/suit/cs/1.jpg'} className="md:hidden object-cover w-full h-[500px]" />

            <div className="relative pt-0 md:pt-40 -mt-20 md:mt-0">

                <img src={imgDir() + '/suit/cs/1.jpg'} className="hidden md:block absolute top-0 left-0 w-[calc(50vw+260px)] h-[860px] lg:h-[1185px] object-cover" />
                <div className="absolute bottom-0 left-0 w-screen overflow-hidden h-[560px] lg:h-[800px] hidden md:block">
                    <div id="ticker" className="absolute top-0 left-0 flex w-[1620px]">
                        {[4,2,3,4,2,3,4,2,3].map((i,j)=>(
                        <img 
                            key={j}
                            src={imgDir() + '/suit/cs/'+i+'@2x.jpg'} 
                            alt=""
                            id={`ticker-${j}`}
                            className={`w-[420px] lg:w-[600px] h-[560px] lg:h-[800px] object-cover ${j===0? 'opacity-0':''}`}
                        />
                        ))}
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 relative">
                    <div>
                    </div>
                    <div className="md:h-[800px] lg:h-[1200px]">
                        <img src={imgDir() + '/suit/logo-cs.svg'} className="px-6 lg:px-0 md:w-[532px] h-auto"/>
                        <div className="mt-10 px-6">
                            <h2 className=" text-xl lg:text-3xl !leading-loose">日本で屈指の工場が作る<br />最高品質の着心地とデザイン</h2>
                            <p className=" leading-[30px] text-xs mt-6 max-w-lg">
                            国内で五本の指に入るハイクオリティなオーダー専業工場にて作るC.Sシリーズ。<br />
                            熟練の職人たちと、その技術をファッションに昇華させる若手との絶妙なバランスで、最高峰の着心地と流れるようなシルエットを実現しました。<br />
                            これまでにない最高の一着や、ここぞの一張羅をお求めの方に最適な、チェントトレンタが誇るトップレベルのオーダーライン。
                            </p>
                        </div>
                    </div>
                </div>

                <div className="relative w-screen overflow-hidden h-[260px] my-10 md:hidden">
                    <div id="ticker-sm" className="absolute top-0 left-0 flex w-[1620px]">
                        {[2,3,4,2,3,4].map((i,j)=>(
                        <img 
                            key={j}
                            src={imgDir() + '/suit/cs/'+i+'.jpg'} 
                            alt=""
                            className="w-[180px] h-[260px] object-cover"
                        />
                        ))}
                    </div>
                </div>
                
                <div className="max-w-[980px] mx-auto relative px-6 lg:px-0">
                    <div className="h-[340px] ">
                        <h2 className=" text-3xl leading-loose">大人の色気漂う<br />ラグジュアリーな立ち姿を。</h2>
                        <p className=" leading-[30px] text-xs mt-6 md:w-[512px] tracking-wide text-justify">
                        流れるようなショルダーラインや、ゆとりを持ちつつもエレガントな曲線を描くアームホールなど、職人の手作業が入るからこそ実現できるディティールが満載のC.Sシリーズ。<br />
                        「神は細部に宿る」その言葉のままに、ハンガーに吊るしてあるだけでは表れない、袖を通してこそ立ち上る色気が特徴です。
                        </p>
                    </div>
                </div>
            </div>

            <div className="max-w-[1100px] mx-auto mt-28 pb-40 px-4 xl:px-0">
                <h3 className="text-5xl playfair font-bold tracking-widest">PRICE</h3>
                <div className="bg-white mt-8 hidden md:block">
                    <table className="w-full border-spacing-px border-separate text-center zen">
                        <tbody>
                            <tr>
                                <th colSpan={2} className="bg-[#4B3A28] p-2 text-xl">
                                    2ピース
                                </th>
                                <th className="bg-[#4B3A28] p-2 text-xl">3ピース</th>
                                <th colSpan={2} className="bg-[#4B3A28] p-2 text-xl">
                                    ジャケット
                                </th>
                                <th className="bg-[#4B3A28] p-2 text-xl">パンツ</th>
                            </tr>
                            <tr>
                                <td className="bg-[#4B3A28] p-2 text-[15px]">シングル</td>
                                <td className="bg-[#4B3A28] p-2 text-[15px]">ダブル</td>
                                <td className="bg-[#4B3A28] p-2 text-[15px]">シングル</td>
                                <td className="bg-[#4B3A28] p-2 text-[15px]">シングル</td>
                                <td className="bg-[#4B3A28] p-2 text-[15px]">ダブル</td>
                                <td className="bg-[#4B3A28] p-2 text-[15px]"></td>
                            </tr>
                            <tr>
                                <td className="bg-[#4B3A28] p-2 text-[15px]">¥130,000~</td>
                                <td className="bg-[#4B3A28] p-2 text-[15px]">¥138,000~</td>
                                <td className="bg-[#4B3A28] p-2 text-[15px]">¥166,000~</td>
                                <td className="bg-[#4B3A28] p-2 text-[15px]">¥85,000~</td>
                                <td className="bg-[#4B3A28] p-2 text-[15px]">¥92,000~</td>
                                <td className="bg-[#4B3A28] p-2 text-[15px]">¥50,000~</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="md:hidden mt-6 space-y-4">
                    <div className="bg-white">
                        <table className="w-full border-spacing-px border-separate text-left md:text-center zen table-fixed">
                            <tbody>
                                <tr>
                                    <th colSpan={2} className="bg-[#4B3A28] p-2 text-xl">
                                        2ピース
                                    </th>
                                </tr>
                                <tr>
                                    <td className="bg-[#4B3A28] p-2 text-[15px]">シングル</td>
                                    <td className="bg-[#4B3A28] p-2 text-[15px]">¥130,000~</td>
                                </tr>
                                <tr>
                                    <td className="bg-[#4B3A28] p-2 text-[15px]">ダブル</td>
                                    <td className="bg-[#4B3A28] p-2 text-[15px]">¥138,000~</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="bg-white">
                        <table className="w-full border-spacing-px border-separate text-left md:text-center zen table-fixed">
                            <tbody>
                                <tr>
                                    <th colSpan={2} className="bg-[#4B3A28] p-2 text-xl">
                                        3ピース
                                    </th>
                                </tr>
                                <tr>
                                    <td className="bg-[#4B3A28] p-2 text-[15px]">シングル</td>
                                    <td className="bg-[#4B3A28] p-2 text-[15px]">¥166,000~</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="bg-white">
                        <table className="w-full border-spacing-px border-separate text-left md:text-center zen table-fixed">
                            <tbody>
                                <tr>
                                    <th colSpan={2} className="bg-[#4B3A28] p-2 text-xl">
                                        ジャケット
                                    </th>
                                </tr>
                                <tr>
                                    <td className="bg-[#4B3A28] p-2 text-[15px]">シングル</td>
                                    <td className="bg-[#4B3A28] p-2 text-[15px]">¥85,000~</td>
                                </tr>
                                <tr>
                                    <td className="bg-[#4B3A28] p-2 text-[15px]">ダブル</td>
                                    <td className="bg-[#4B3A28] p-2 text-[15px]">¥92,000~</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="bg-white">
                        <table className="w-full border-spacing-px border-separate text-left md:text-center zen table-fixed">
                            <tbody>
                                <tr>
                                    <th colSpan={2} className="bg-[#4B3A28] p-2 text-xl">
                                        パンツ
                                    </th>
                                </tr>
                                <tr>
                                    <td className="bg-[#4B3A28] p-2 text-[15px]"></td>
                                    <td className="bg-[#4B3A28] p-2 text-[15px]">¥50,000~</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <h5 className="text-lg md:text-2xl mt-20 tracking-wider"><span className="inline-block border-b border-b-white pb-2">CS SERIESについて問い合わせる</span></h5>
                <p className="text-xs tracking-wider mt-10 leading-relaxed">生地、デザイン、お値段などなんでもお気軽にお問い合わせください。チェントトレンタは皆様のスーツライフを応援いたします。</p>
                <div className="flex flex-col md:flex-row mt-10 gap-10">
                    <a href="mailto:info@centotrenta.jp" className="relative block w-60 py-2 text-sm tracking-wider font-bold group bg-[#4B3A28] text-white">
                        <div className="absolute inset-0 border border-white ">
                        </div>
                        メールでお問い合わせ
                        <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-[#4B3A28] border border-white pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                        メールでお問い合わせ
                        </div>
                    </a>
                    <a href="tel:06-6245-1268" className="relative block w-60 py-2 text-sm tracking-wider font-bold group bg-[#4B3A28] text-white">
                        <div className="absolute inset-0 border border-white ">
                        </div>
                        お電話でお問い合わせ
                        <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-[#4B3A28] border border-white pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                        お電話でお問い合わせ
                        </div>
                    </a>
                </div>
            </div>
            <div className="h-[280px] md:h-[480px] lg:h-[680px] bg-[#005880] relative">
                <img src={imgDir() + '/suit/cs/banner-cc.jpg'} className="absolute top-0 left-0 w-[70vw] md:w-[80vw] lg:w-[calc(100vw-400px)] h-full object-cover object-right" />
                <div className="absolute right-4 lg:right-40 top-6 md:top-12 lg:top-28">
                    <img src={imgDir() + '/suit/logo-cc.svg'} className="w-[200px] md:w-[300px] lg:w-[393px] h-auto ml-auto"/>
                    <h4 className=" md:text-2xl leading-loose text-right mt-4 md:mt-10">豊かなカスタム力とそれに反した<br />コストパフォーマンス</h4>
                    <button onClick={()=>linkTo("/order-suit/cc")} className="relative block ml-auto mt-4 md:mt-10 w-52 py-2 text-sm tracking-wider font-bold group bg-[#005880] text-[#005880] border border-white">
                        <div className="absolute inset-0  ">
                        </div>
                        CC SERIESを見る
                        <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                        CC SERIESを見る
                        </div>
                    </button>
                </div>
            </div>

        </div>
        </>
    )
}