import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../util";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function PreviewSingle(props: any) {
    const [post, setPost] = useState<any| null>(null)
    const search = useLocation().search
    const query = new URLSearchParams(search)
    const post_type = query.get('post_type')? query.get('post_type'): 'posts'
    const p = query.get('p')
    
    const navigate = useNavigate()

    useEffect(()=>{
        const token = document.body.getAttribute('nonce')
        if (token) {
            // WPログイン中、下書きも取得する
            axios.get(apiUrl() + '/wp-json/wp/v2/'+post_type+'/'+ p +'?status=draft', {
                headers: {
                    'X-WP-Nonce': token
                }
            }).then(res => {
                if (!res.data) {
                    navigate('/blogs')
                } else {
                    setPost(res.data)
                }
            }).catch(error => {
                navigate('/blogs')
            })
        } else {
            // navigate('/')
        }

    }, [])

    return (
        <div className="py-32">
            <div className="lg:w-[640px] mx-auto px-6 lg:px-0 single-content">
                {post && (
                    <>
                        <h1  dangerouslySetInnerHTML={{__html: post.title.rendered}} className="text-2xl" ></h1>
                        <div dangerouslySetInnerHTML={{__html: post.content.rendered}} className=" mt-8 text-xs leading-6">
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}