import React from "react";
import { imgDir } from "../../util";
import { useDispatch } from "react-redux";
import { setTransitionStarter } from "../../redux/effectSlice";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const vassStyle = () => {
    const css = `
        #vassTypo {
            animation: vassAnimation 16s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
        @keyframes vassAnimation {
            0% {
                left:0px;
            }
            100% {
                left: -1396px;
            }
        }

    `
    return (
        <style>
            {css}
        </style>
    )
}

export default function Vass(props: any) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }
    return (
        <>
            {vassStyle()}
            <section className="section bg-[#F0F0F0] pt-24 lg:pt-40 pb-20 relative">
                <div className="relative mx-auto">
                    <h6 className="text-center font-bold tracking-wider">the brands we deal with</h6>
                    <div className="relative w-screen overflow-hidden">
                        <div id="vassTypo" className="absolute top-6 left-0 flex space-x-10 text-[240px] cormorant text-white" style={{width: 748*2*3 + 'px'}}>
                            <div>
                                VASS SHOES
                            </div>
                            <div>
                                VASS SHOES
                            </div>
                            <div>
                                VASS SHOES
                            </div>
                        </div>
                        <img src={imgDir() + '/d-te/vass/shoe.png'}  className="relative block mx-auto h-[300px] mt-8 mb-4"/>
                    </div>
                    <h3 className="text-center font-bold tracking-wider text-3xl">Vass Shoes</h3>
                    <div className="max-w-[640px] mx-auto px-4">
                        <p className=" text-sm mt-8 leading-loose zen">
                            ヨーロッパにおいては靴の名産地としてイギリスと存在感を二分する国、ハンガリー。<br />
                            <br />
                            その首都であるブダペストにおいて、伝説的靴職人であるラズロ・ヴァーシュ氏により、1978年に立ち上げられたブランドです。<br />
                            <br />
                            日本では馴染みの少ないだろう東欧の靴文化ですが、良くも悪くも靴の工業化が遅れていた東欧諸国では、いまだに靴を手作業で組み立てていく文化が着実に発展しており、ヴァーシュ氏の登場によってその技術力を世界に見せつけたのでした。
                        </p>
                    </div>
                    <div className="px-4">
                        <div className="md:flex gap-4 justify-center mt-8">
                            <img alt="" className="w-full md:w-[240px] lg:w-[320px] xl:w-[423px] h-auto" src={imgDir() + '/d-te/vass/1@2x.jpg'} />
                            <img alt="" className="mt-4 md:mt-0 w-full md:w-[240px] lg:w-[320px] xl:w-[423px] h-auto" src={imgDir() + '/d-te/vass/2@2x.jpg'} />
                            <img alt="" className="mt-4 md:mt-0 w-full md:w-[240px] lg:w-[320px] xl:w-[423px] h-auto" src={imgDir() + '/d-te/vass/3@2x.jpg'} />
                        </div>
                    </div>
                    <div className="max-w-[640px] mx-auto px-4">
                        <p className=" text-sm mt-8 leading-loose zen">
                        考え抜かれた立体的な木型から生まれる極上の履き心地。<br />
                        妥協を許さない高品質なマテリアル。<br />
                        そして長い歴史を背景に培われてきた確かな職人技。<br />
                        その全てが秩序をもって一つとなり、VASSの靴は生み出されます。
                        </p>
                    </div>
                    <div className="px-4">
                        <div className="md:flex gap-4 justify-center mt-8">
                            <img className="w-full md:w-[360px] lg:w-[423px] h-auto" src={imgDir() + '/d-te/vass/4@2x.jpg'} />
                            <img className="mt-4 md:mt-0 w-full md:w-[360px] lg:w-[423px] h-auto" src={imgDir() + '/d-te/vass/5@2x.jpg'} />
                        </div>
                    </div>
                    <div className="max-w-[640px] mx-auto px-4">
                        <p className=" text-sm mt-8 leading-6 zen">
                            決して派手ではない堅実な靴づくりによる製品たちは<br />
                            トラディショナルでありながら圧倒的な存在感を放ちます。<br />
                            「既成靴の最高峰」と称されるその完成度は、足を通した者にしか解らない、まさに究極の逸品です。
                        </p>
                    </div>
                    <div className="px-4">
                        <div className="md:flex gap-4 justify-center mt-8">
                            <img className="w-full md:w-[360px] lg:w-[423px] h-auto" src={imgDir() + '/d-te/vass/6@2x.jpg'} />
                            <img className="mt-4 md:mt-0 w-full md:w-[360px] lg:w-[423px] h-auto" src={imgDir() + '/d-te/vass/7@2x.jpg'} />
                        </div>
                    </div>
                    <div className="max-w-[640px] mx-auto px-4">
                        <p className=" text-sm mt-8 leading-6 zen">
                        デニムなどのカジュアルスタイルや、ミリタリーやワークなどのビンテージスタイルとあわせると、コーデにさりげなく品を添えてくれます。アーバンかつスマートな大人のカジュアルコーデにこそ加えて欲しいブランドです。
                        </p>
                    </div>
                    <div className="px-4">
                        <div className="md:flex gap-4 justify-center mt-8">
                            <img className="w-full md:w-[360px] lg:w-[498px] h-auto" src={imgDir() + '/d-te/vass/8@2x.jpg'} />
                            <img className="mt-4 md:mt-0 w-full md:w-[360px] lg:w-[498px] h-auto" src={imgDir() + '/d-te/vass/9@2x.jpg'} />
                        </div>
                    </div>
                    <div className="flex justify-center mt-12">
                        <a href="https://centotrenta.shop-pro.jp/?mode=grp&gid=2956715&sort=n" target="_blank" rel="noreferrer" className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                            <div className="absolute inset-0 border border-black ">
                            </div>
                            商品一覧
                            <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                            商品一覧
                            </div>
                        </a>
                    </div>
                </div>
            </section>
            <section className="bg-white pt-14 pb-40">
                <div className="max-w-[740px] mx-auto px-4 lg:px-0">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8">
                        <div className="flex flex-col">
                            <img src={imgDir() + '/d-te/laszlo/shoe.png'}  className="relative block mx-auto h-[300px] object-contain mt-8 mb-4"/>
                            <h3 className="text-center font-bold tracking-wider text-2xl">Laszlo Budapest</h3>
                            <p className=" text-sm zen mt-6 leading-6 grow">
                                ヨーロッパ有数の靴生産地であるハンガリーで100年以上の歴史を持つ、隠れた名門。ハンドメイドの靴作りの技術を、職人たちが今も守り続けています。
                            </p>
                            <div className="flex justify-center mt-8">
                                <button onClick={()=>linkTo("/d-te/laszlo-budapest")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                    <div className="absolute inset-0 border border-black ">
                                    </div>
                                    MORE
                                    <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                    MORE
                                    </div>
                                </button>
                            </div>

                        </div>
                        <div className="flex flex-col">
                            <img src={imgDir() + '/d-te/saint_crispins/shoe.png'}  className="relative block mx-auto h-[300px] object-contain mt-8 mb-4"/>
                            <h3 className="text-center font-bold tracking-wider text-2xl">Saint Crispin's</h3>
                            <p className=" text-sm zen mt-6 leading-6 grow">
                            シューズデザイナーであるマイケル・ローリック氏によって、1992年にオーストリアで設立されたシューズブランド。
                            </p>
                            <div className="flex justify-center mt-8">
                                <button onClick={()=>linkTo("/d-te/saint-crispins")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                    <div className="absolute inset-0 border border-black ">
                                    </div>
                                    MORE
                                    <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                    MORE
                                    </div>
                                </button>
                            </div>

                        </div>
                        <div className="flex flex-col">
                            <img src={imgDir() + '/vintage@2x.png'}  className="relative block mx-auto h-[300px] object-contain mt-8 mb-4"/>
                            <h3 className="text-center font-bold tracking-wider text-2xl">VINTAGE</h3>
                            <p className=" text-sm zen mt-6 leading-6 grow">
                            次の世代へと受け継ぐことができる…。それも革靴の大きな魅力です。履かれなくなった靴に、新たな一歩を与えてください。
                            </p>
                            <div className="flex justify-center mt-8">
                                <button onClick={()=>linkTo("/d-te/vintage")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                                    <div className="absolute inset-0 border border-black ">
                                    </div>
                                    MORE
                                    <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                    MORE
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}