import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../../util";
import SidebarNews from "../../Components/SidebarNews";
import ArchivePost from "../../Components/ArchivePost";

export default function HomeNews(props: any) {
    const [posts, setPosts] = useState([])
    useEffect(() => {
        axios.get(apiUrl() + '/wp-json/wp/v2/posts?_embed').then(res => {
            setPosts(res.data)
        })
    }, [])
    const changeCategory = (categoryId: number | null) => {
        let url = apiUrl() + '/wp-json/wp/v2/posts?_embed'
        if (categoryId) {
            url += '&categories=' + categoryId
        }
        axios.get(url).then(res => {
            setPosts(res.data)
        })
    }
    return (
        <div className="px-4 lg:px-20">
            <div className="lg:w-[940px] lg:h-screen shrink-0 py-10 lg:py-40">
                <div className="lg:flex lg:space-x-8">
                    <div className="lg:w-40 shrink-0 ">
                        <SidebarNews changeCategory={changeCategory}/>
                    </div>
                    <div className="grow mt-8 lg:mt-0">
                        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-8">
                            {posts.map((post: any) => (
                                <div key={post.id}>
                                    <ArchivePost post={post} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}