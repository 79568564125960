import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setOpened } from "../redux/effectSlice";

export default function OpeningEffectShort(pros: any) {
    const [open, setOpen] = useState(false)
    const [showing, setShowing] = useState(true)
    const dispatch = useDispatch()

    useEffect(()=>{
        setTimeout(()=>{
            setOpen(true)
        }, 500)
        setTimeout(()=>{
            setShowing(false)
            dispatch(setOpened(true))
        }, 1100)
    }, [])
    return (
        <>
            {showing && (
                <div className="fixed top-0 left-0 w-screen h-screen overflow-hidden z-50 transition-opacity duration-500 delay-500">
                    <div className={`absolute top-0 left-0 w-full transition-all duration-500 bg-black ${!open? 'h-[50vh]': 'h-[0vh]'}`}>
                    </div>
                    <div className={`absolute bottom-0 left-0 w-full transition-all duration-500 bg-black ${!open? 'h-[50vh]': 'h-[0vh]'}`}>
                    </div>
                </div>
            )}
        </>
    )
}